import axios from "axios";
import { useEffect, useState } from "react";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

const doctorId = (window as any).skindrWidgetDoctorId;
const language = (window as any).skindrWidgetLanguage ?? "nl";
const source = (window as any).skindrWidgetSource;

i18n.use(initReactI18next).init({
  resources: {
    nl: {
      translation: {
        start_online_consultation_with: "Start een online consultatie met",
        doctor: "Dr.",
      },
    },
    en: {
      translation: {
        start_online_consultation_with: "Start an online consultation with",
        doctor: "Dr.",
      },
    },
    fr: {
      translation: {
        start_online_consultation_with:
          "Démarrer une consultation en ligne avec",
        doctor: "Dr.",
      },
    },
  },
  supportedLngs: ["nl", "fr", "en"],
  lng: language,
  fallbackLng: "nl",
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  const { t } = useTranslation();
  const [doctor, setDoctor] = useState<any>(null);

  useEffect(() => {
    async function collectDoctor() {
      const doctorResponse = await axios({
        method: "GET",
        url: `https://api.skindr.com/api/doctors/${doctorId}`,
      });

      if (doctorResponse?.data?.data?.doctor) {
        setDoctor(doctorResponse.data.data.doctor);
      }
    }

    if (doctorId) {
      collectDoctor();
    }
  }, []);

  if (!doctor) {
    return null;
  }
  return (
    <div id="skindrWidget" className="skindrWidget">
      <div className="skindr-widget-bg-white skindr-widget-overflow-hidden skindr-widget-shadow skindr-widget-rounded-xl skindr-widget-border skindr-widget-border-pink-200 skindr-widget-w-max hover:skindr-widget-border-pink-300 hover:skindr-widget-bg-gray-50">
        <a
          href={`https://app.skindr.com/create/new?utm_source=${source}&utm_medium=button&lng=${language}`}
        >
          <div className="skindr-widget-p-1 md:skindr-widget-p-2 lg:skindr-widget-p-4">
            <div className="skindr-widget-flex">
              <div className="skindr-widget-flex skindr-widget-items-center">
                <img
                  className="skindr-widget-block skindr-widget-h-16 lg:skindr-widget-h-20 skindr-widget-w-auto skindr-widget-p-0"
                  src="https://app.skindr.com/assets/assets/images/logo.png"
                  alt="Skindr"
                />
                <div className="skindr-widget-ml-3">
                  <h1 className="skindr-widget-text-sm lg:skindr-widget-text-base skindr-widget-font-medium skindr-widget-leading-5 lg:skindr-widget-leading-6 skindr-widget-text-gray-900 skindr-widget-truncate skindr-widget--mt-1">
                    {t("start_online_consultation_with")}
                  </h1>

                  <div className="skindr-widget-flex skindr-widget-items-center">
                    <img
                      className="skindr-widget-h-8 skindr-widget-w-8 lg:skindr-widget-h-12 lg:skindr-widget-w-12 skindr-widget-rounded-full skindr-widget-object-cover skindr-widget-p-0"
                      src={doctor.avatar}
                      alt={doctor.first_name}
                    />
                    <div className="skindr-widget-ml-3">
                      <h1 className="skindr-widget-text-sm lg:skindr-widget-text-base skindr-widget-font-normal skindr-widget-leading-5 lg:skindr-widget-leading-6 skindr-widget-text-gray-900 skindr-widget-truncate">
                        {t("doctor")} {doctor.first_name} {doctor.last_name}
                      </h1>
                      {doctor?.doctor_settings?.practice && (
                        <p className="skindr-widget-flex skindr-widget-items-center skindr-widget-text-xs lg:skindr-widget-text-sm skindr-widget-leading-4 skindr-widget-text-gray-500 skindr-widget-font-light lg:skindr-widget-mr-4">
                          <svg
                            className="skindr-widget-flex-shrink-0 skindr-widget-mr-1.5 skindr-widget-h-5 skindr-widget-w-5 skindr-widget-text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                            />
                          </svg>
                          {doctor.doctor_settings.practice}
                        </p>
                      )}
                    </div>
                    <p className="skindr-widget-ml-3 skindr-widget-text-gray-900">
                      →
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default App;
